<template>
  <div class="view-404">
    <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Erreur 404</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">

      <div class="millibase-logo">
        <i class="fa fa-cubes"></i> Millibase - Familles
      </div>

      La page demandée n'existe pas

    </ion-content>
  </ion-page>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'View404',
  components: {
  },
  data() {
    return {
    }
  },
}
</script>

<style>
</style>
